import { SORT_CODE } from '@components/filter/constants';
import { EXTENSION_CODE } from 'src/constants';
import { LanguageCodeEnum, ProductCardsWithBrandDocument } from 'src/generated/graphql';
import { SummaryProducts } from './type';

export const filterCategory = (products: SummaryProducts, category: string) => {
  return products.filter((product) => product.categoryCode.includes(category));
};

export const filterExtension = (products: SummaryProducts, extensions: string[]) => {
  return products.filter((e) => {
    return (
      e.id &&
      extensions.find((f) => {
        return e.extensions?.find((g) => {
          let needle = g.replace(/\./g, '');
          needle = EXTENSION_CODE[needle?.toUpperCase()] ? needle : EXTENSION_CODE.ETC;
          return f === needle;
        });
      }) !== undefined
    );
  });
};

export const sortProducts = (products: SummaryProducts, sort: string, isFreeSort: boolean) => {
  return products.sort((a, b) => {
    if (isFreeSort && Boolean(a.salePrice) !== Boolean(b.salePrice)) return a.salePrice ? -1 : 1;
    switch (sort) {
      case SORT_CODE.ACCURACY:
        return b.score - a.score;
      case SORT_CODE.POPULARITY:
        return b.orderCount - a.orderCount;
      case SORT_CODE.NEWEST:
        return b.id - a.id;
      case SORT_CODE.LOWER_PRICE:
        return a.salePrice - b.salePrice;
      case SORT_CODE.HIGHER_PRICE:
        return b.salePrice - a.salePrice;
    }
    return 0;
  });
};

export const extensionCount = (extensions: string[][], checkExtensions: EXTENSION_CODE[], isInclude: boolean) => {
  return extensions.filter((x) => x.some((y) => isInclude === checkExtensions.includes(y as EXTENSION_CODE))).length;
};

export const getExtensionCount = (products: SummaryProducts) => {
  const extensions = products.map((x) => x.extensions);
  return {
    skp_cs3o: extensionCount(extensions, [EXTENSION_CODE.SKP, EXTENSION_CODE.CS3O], true),
    blend: extensionCount(extensions, [EXTENSION_CODE.BLEND], true),
    obj_fbx: extensionCount(extensions, [EXTENSION_CODE.OBJ, EXTENSION_CODE.FBX], true),
    etc: extensionCount(
      extensions,
      [EXTENSION_CODE.SKP, EXTENSION_CODE.CS3O, EXTENSION_CODE.BLEND, EXTENSION_CODE.OBJ, EXTENSION_CODE.FBX],
      false,
    ),
  };
};

export const productCardQuery = (productNos: number[], languageCode: LanguageCodeEnum) => {
  return {
    query: ProductCardsWithBrandDocument,
    variables: {
      productNos,
      languageCode,
    },
  };
};
