import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useSessionStorage } from 'react-use';
import { CellMeasurerCache } from 'react-virtualized';
import { LanguageCodeEnum, ProductCardsWithBrandQuery, useProductCardsWithBrandQuery } from 'src/generated/graphql';
import * as ga from '@lib/ga';
import { BeforeContents, SummaryProducts } from '@components/common/Products/type';
import { filterCategory, filterExtension, sortProducts } from '@components/common/Products/action';

type Props = {
  pageType: string;
  summaryProducts: SummaryProducts;
  sort?: string;
  isFreeSort?: boolean;
};

const cache = new CellMeasurerCache({
  defaultHeight: 377,
  fixedWidth: true,
});

export default function useProduct({ pageType, summaryProducts = [], sort, isFreeSort = true }: Props) {
  const defaultLimit = 18;
  const { i18n } = useTranslation('common');

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [beforeContents, setBeforeContents] = useSessionStorage<BeforeContents>('beforeProdcuts');
  const [products, setProducts] = useState<ProductCardsWithBrandQuery['productCards'] | null>(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(defaultLimit);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSort, setSelectedSort] = useState<string | null>(sort);
  const [selectedExtensions, setSelectedExtensions] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const onClearProducts = () => {
    setProducts([]);
    setOffset(0);
    setLimit(defaultLimit);
    cache.clearAll();
  };

  const onAddProducts = (productList: ProductCardsWithBrandQuery['productCards']) => {
    if (!products || products.length === 0) {
      setProducts(productList);
    } else {
      // 중복된 상품 제거. 조회 시점이 차이가 나면 중복된 상품이 존재할 수 있다. 특히 SSR에서 내려온 정보를 사용할 때
      const existingGoods = products.flatMap((item) => item.id);
      const dedupNewProducts = productList.filter((item) => !existingGoods.includes(item.id));
      setProducts([...products, ...dedupNewProducts]);
    }
  };

  const onSelectExtensions = (extension: string[]) => {
    onClearProducts();
    setSelectedExtensions(extension);
  };

  const onSelectSort = (sort: string) => {
    if (selectedSort === sort) return;
    onClearProducts();
    setSelectedSort(sort);
  };

  const onSelectCategory = (category: string) => {
    onClearProducts();
    setSelectedCategory(category);
  };

  const getFilterCategoryProducts = (categoryCode: string) => {
    if (!categoryCode) return summaryProducts;
    return filterCategory(summaryProducts, categoryCode);
  };

  const getFilterExtensionProducts = (extensions: string[]) => {
    if (!extensions && extensions.length === 0) return summaryProducts;
    return filterExtension(summaryProducts, extensions);
  };

  const getSortProducts = (sort: string, isFreeSort: boolean) => {
    if (!sort) return summaryProducts;
    return sortProducts(summaryProducts, sort, isFreeSort);
  };

  const nextOffset = () => {
    if (Boolean(isProductsLoading) || isLoading) return;
    setIsLoading(true);
    setOffset((prev) => prev + 1);
  };

  const filterProductsNos = (() => {
    let products = [...summaryProducts];
    if (!products) return [];
    if (selectedCategory) products = filterCategory(products, selectedCategory);
    if (selectedExtensions.length > 0) products = filterExtension(products, selectedExtensions);
    if (selectedSort) products = sortProducts(products, selectedSort, isFreeSort);
    return products.map((e) => e.id || 0);
  })();
  const sliceProductsNos = filterProductsNos.slice(offset * limit, (offset + 1) * limit);

  const { loading, error, data } = useProductCardsWithBrandQuery({
    variables: {
      languageCode: i18n.language as LanguageCodeEnum,
      productNos: sliceProductsNos,
    },
    skip: sliceProductsNos.length === 0 || !selectedSort,
  });
  const isProductsLoading = loading || error;
  const productCard = !isProductsLoading && data?.productCards;

  useEffect(() => {
    if (sort) setSelectedSort(sort);
  }, [sort]);

  useEffect(() => {
    if (productCard && productCard.length > 0) {
      onAddProducts(productCard);

      if (beforeContents && beforeContents.offset > offset) setOffset((prev) => prev + 1);

      if (beforeContents && beforeContents.offset === offset) {
        // [todo]: 스크롤 뒤로가기 작업 진행 중이라 주석 처리
        // window.scrollTo({ top: beforeContents.scrollY });
        setIsLoading(false);
      }

      if (!beforeContents) setIsLoading(false);
    }

    if (!isProductsLoading && summaryProducts.length === 0) setIsLoading(false);
  }, [productCard, isProductsLoading, selectedExtensions, selectedSort, selectedCategory]);

  useEffect(() => {
    // 클라이언트에서만 실행
    if (typeof window !== 'undefined') {
      if (typeof products !== 'undefined' && products && products.length > 0) {
        const viewItems = products.map((product, idx) => {
          return {
            id: product.id,
            name: product.title,
            list_name: `CardFilterGroup ${pageType}`,
            brand: product.brand.code,
            list_position: idx,
            price: product.price,
          };
        });

        ga.event({
          ecommerce: {
            impressions: viewItems,
          },
        });
      }
    }
  }, [products]);

  return {
    selectedExtensions,
    selectedSort,
    filterCount: filterProductsNos.length,
    products,
    offset,
    limit,
    cache,
    isLoading:
      Boolean(isProductsLoading) || isLoading || (sliceProductsNos.length > 0 && (products || []).length === 0),
    onSelectExtensions,
    onSelectSort,
    onSelectCategory,
    onAddProducts,
    onClearProducts,
    getFilterCategoryProducts,
    getFilterExtensionProducts,
    getSortProducts,
    nextOffset,
  };
}
